html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.container {
  flex: 1; /* Esto permite que el contenedor crezca y ocupe el espacio disponible */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

body {
  background-color: #f4f4f4;
  color: #333;
  background-image: url('./../assets/fondo.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.mapContainer {
  width: 100%;
  height: 450px;
  margin-top: 20px;
}

.titulo {
  text-align: center;
  margin-top: 20px;
}

.preSelect {
  display: block;
  margin-bottom: 5px;
}

.select {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
}

.data {
  text-align: left;
  margin-top: 20px;
}

.text {
  margin: 5px 0;
}

.link {
  display: block;
  margin-top: 10px;
  color: #1976d2;
  text-decoration: none;
}

.noData {
  text-align: center;
  margin-top: 20px;
}

.footer {
  position: fixed;
  bottom: 45px;
  width: 100%;
  height: 40px;
  left: 0;
}